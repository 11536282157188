<template>
  <app-search-bar :options="appSearchOptions">
    <template #default="{ query, handleQueryUpdated, handleFocus }">
      <div class="navbar__search__input-wrap">
        <input
          type="search"
          :value="query"
          name="query"
          class="form-control"
          placeholder="Vind uw geschenk..."
          @input="handleQueryUpdated"
          @focus="handleFocus"
        />

        <button type="submit" title="Zoeken">
          <i class="icon-search"></i>
        </button>
      </div>
    </template>

    <template #suggestions="{ query, suggestions }">
      <suggestions :query="query" :suggestions="suggestions" />
    </template>
  </app-search-bar>
</template>

<script>
import AppSearchBar from "./components/functional/AppSearchBar.vue";
import Suggestions from "./components/Suggestions.vue";

export default {
  components: {
    AppSearchBar,
    Suggestions,
  },
  data() {
    return {};
  },
  computed: {
    appSearchOptions() {
      return {
        client: {
          searchKey: window.appSearchKey,
          endpointBase: window.appSearchBaseUrl,
          engineName: window.appSearchEngine,
        },
        resultFields: {
          image_url: {
            raw: {},
          },
          name: {
            raw: {},
          },
          minimum_price: {
            raw: {},
          },
          minimum_discount_price: {
            raw: {},
          },
          url: {
            raw: {},
          },
        },
      };
    },
  },
};
</script>
