<template>
  <form
    ref="form"
    class="navbar__search"
    :action="`/search?query=${query}`"
    method="get"
  >
    <slot
      :query="query"
      :handle-query-updated="handleQueryUpdated"
      :handle-focus="handleFocus"
    ></slot>
    <slot
      v-if="showSuggestions"
      :query="query"
      name="suggestions"
      :suggestions="suggestions"
    ></slot>
  </form>
</template>

<script>
import throttle from "lodash.throttle";
import { useAppSearch } from "../../composables/useAppSearch";

export default {
  props: {
    options: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const { createClient, clients } = useAppSearch();

    return {
      createClient,
      clients,
    };
  },
  data() {
    return {
      query: "",
      throttledGetQuerySuggestions: throttle(this.getQuerySuggestions, 500, {
        leading: true,
        trailing: true,
      }),
      suggestions: [],
    };
  },
  computed: {
    showSuggestions() {
      if (this.query.length) {
        document.body.classList.add("show-suggestions");
      } else {
        document.body.classList.remove("show-suggestions");
      }

      return this.query.length;
    },
  },
  created() {
    this.createClient(this.options);
    this.clickOutsideSearch();
  },
  methods: {
    handleQueryUpdated({ target: { value } }) {
      this.query = value;

      if (value === "") {
        this.suggestions = [];
      } else {
        this.throttledGetQuerySuggestions();
      }
    },
    handleFocus({ target: { value } }) {
      if (value !== "") {
        document.body.classList.add("show-suggestions");
      }
    },
    getQuerySuggestions() {
      const {
        client: { engineName },
        resultFields,
      } = this.options;
      const { instance } = this.clients[`${engineName}Client`];

      instance
        .search(this.query, {
          page: {
            size: 8,
          },
          result_fields: resultFields,
        })
        .then(({ results }) => {
          this.suggestions = results;
        })
        .catch((error) => {
          // console.log(error);
        });
    },
    clickOutsideSearch() {
      document.documentElement.addEventListener("click", (e) => {
        if (!this.$refs.form.contains(e.target)) {
          document.body.classList.remove("show-suggestions");
        }
      });
    },
  },
};
</script>
