<template>
  <a :href="productUrl" class="card card--suggestion">
    <figure v-if="productImageUrl">
      <img :src="productImageUrl" :alt="productName">
    </figure>

    <div class="search-suggestion__body">
      <h5 class="search-suggestion__title">
        {{ productName }}
      </h5>

      <div class="price">
        <small v-if="hasDiscount" class="price__old">vanaf {{ oldPrice }}</small>

        <div :class="currentPriceClass">
          vanaf <h3 class="price__current__price">{{ currentPrice }}</h3>
        </div>
      </div>
    </div>
  </a>
</template>

<script>
export default {
  props: {
    suggestion: {
      type: Object,
      required: true,
    },
  },
  computed: {
    productName() {
      return this.suggestion.data.name.raw;
    },
    productImageUrl() {
      return this.suggestion.data.image_url?.raw;
    },
    productUrl() {
      return this.suggestion.data.url?.raw;
    },
    hasDiscount() {
      if (this.suggestion.data.minimum_discount_price && this.suggestion.data.minimum_discount_price.raw > 0 && this.suggestion.data.minimum_discount_price.raw < this.suggestion.data.minimum_price.raw) {
        return true;
      }

      return false;
    },
    currentPrice() {
      if (this.hasDiscount) {
        return new Intl.NumberFormat('nl-NL', {
          style: 'currency',
          currency: 'EUR',
        }).format(this.suggestion.data.minimum_discount_price.raw);
      }

      return new Intl.NumberFormat('nl-NL', {
        style: 'currency',
        currency: 'EUR',
      }).format(this.suggestion.data.minimum_price.raw);
    },
    oldPrice() {
      return new Intl.NumberFormat('nl-NL', {
        style: 'currency',
        currency: 'EUR',
      }).format(this.suggestion.data.minimum_price.raw);
    },
    currentPriceClass() {
      let classString = 'price__current';

      if (this.hasDiscount) {
        classString += ' text-orange';
      }

      return classString;
    },
  },
};
</script>
