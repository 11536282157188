<template>
  <div class="navbar__search__suggestions">
    <suggestion v-for="suggestion in suggestions" :key="suggestion.data._meta.id" :suggestion="suggestion" />

    <button v-if="suggestions.length === 0" type="submit" class="card card--suggestion">
      <svg class="typeahead__no-suggestions-icon" viewBox="0 0 26.4 20.7">
        <path class="st0" d="M19.5 20.7V15h-12c-2.1 0-3.8-.7-5.3-2.2C.7 11.3 0 9.6 0 7.5c0-2.1.7-3.8 2.2-5.3C3.7.7 5.4 0 7.5 0h.4v2.4h-.4c-1.4 0-2.6.5-3.6 1.5S2.4 6.1 2.4 7.5s.5 2.6 1.5 3.6 2.2 1.5 3.6 1.5h12V6.9l6.9 6.9-6.9 6.9z"></path>
      </svg>

      <h5>
        Zoeken naar {{ query }}
      </h5>
    </button>
  </div>
</template>

<script>
import Suggestion from './Suggestion.vue';

export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    Suggestion,
  },
  props: {
    suggestions: {
      type: Array,
      required: true,
    },
    query: {
      type: String,
    },
  },
};
</script>
